import { setLocal } from '../utils/localStorage';

export const EDIT_BOOKMARK = 'EDIT_BOOKMARK ';
export const DELETE_BOOKMARK = 'DELETE_BOOKMARK';
export const ADD_BOOKMARK = 'ADD_BOOKMARK';

const addBookmark = (
  action: {
    type?: any;
    name?: any;
    payload?:
      | { newName: string; newUrl: string }
      | { name: string; newName: string; newUrl: string };
  },
  state: { bookmarks: any },
) => {
  const updatedBookmarks = [...state.bookmarks];
  const newBookmark = {
    id: +new Date(),
    name: action?.payload?.newName,
    url: action?.payload?.newUrl,
    unix: +new Date(),
  };

  updatedBookmarks.push(newBookmark);
  setLocal({ userTiles: updatedBookmarks });
  return { ...state, bookmarks: updatedBookmarks };
};

const deleteBookmark = (id: number, state: { bookmarks: any }) => {
  const updatedBookmarks = [...state.bookmarks];
  const updatedBookmarkInx = updatedBookmarks.findIndex((item) => item.id === id);

  updatedBookmarks.splice(updatedBookmarkInx, 1);
  setLocal({ userTiles: updatedBookmarks });
  if ([...state.bookmarks]?.length <= 1) {
    setLocal({ userTiles: [] });
  }
  return { ...state, bookmarks: updatedBookmarks };
};

const updateBookmark = (
  action: {
    id?: any;
    type?: any;
    unix?: any;
    payload?:
      | { newName: string; newUrl: string }
      | { name: string; newName: string; newUrl: string };
  },
  state: { bookmarks: any },
) => {
  const updatedBookmarks = [...state.bookmarks];
  const updatedBookmarkInx = updatedBookmarks.findIndex(
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    (item) => item.id === action?.payload?.id,
  );
  const updatedBookmark = {
    ...updatedBookmarks[updatedBookmarkInx],
  };

  updatedBookmark.name = action?.payload?.newName;
  updatedBookmark.url = action?.payload?.newUrl;
  updatedBookmark.unix = +new Date();

  updatedBookmarks[updatedBookmarkInx] = updatedBookmark;
  setLocal({ userTiles: updatedBookmarks });

  return { ...state, bookmarks: updatedBookmarks };
};
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export function init(items) {
  if (items) {
    return { bookmarks: items };
  }
  return { bookmarks: [] };
}
export const bookmarkReducers = (
  state: { bookmarks: any },
  action: {
    id?: any;
    type?: any;
    name?: any;
    unix?: any;
    payload?:
      | { newName: string; newUrl: string }
      | { name: string; newName: string; newUrl: string };
  },
) => {
  switch (action.type) {
    case EDIT_BOOKMARK:
      return updateBookmark(action, state);
    case DELETE_BOOKMARK:
      return deleteBookmark(action.id, state);
    case ADD_BOOKMARK:
      return addBookmark(action, state);
    case 'reset':
      return init(action.payload);
    default:
      return state;
  }
};
