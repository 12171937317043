import React, { useState } from 'react';
import { FaviconBackground } from '../FaviconBackground';
import { Icon } from '../Icon';
import { FallbackIcon } from '../FallBackIcon';

type Props = {
  name: string;
  favicon: string;
};

export const Favicon = ({ name, favicon }: Props) => {
  const [error, setError] = useState(false);

  return (
    <div className="relative flex h-[60px] w-[60px] items-center justify-center rounded-[12px] bg-[#FFFAEE]">
      <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-[12px]">
        {/* Favicon Background */}
        <FaviconBackground src={favicon} />

        {/* Favicon */}
        {!error && <Icon src={favicon} setError={setError} error={error} />}
        {error && <FallbackIcon name={name} />}
      </div>
    </div>
  );
};
