import log from 'loglevel';

interface PerformanceEntryType extends PerformanceEntry {
  type: string;
}

/** check if the page was navigated to via a reload */
export const isReload = (): boolean => {
  /**
   * 2022-02-01: Chrome Dev Version 112.0.5582.0 has a bug where performance.getEntriesByType('navigation') returns []
   */
  try {
    const performanceEntry = performance.getEntriesByType('navigation')[0] as PerformanceEntryType;
    if (performanceEntry.type === 'reload') {
      return true;
    }
  } catch (error) {
    log.warn(error);
  }

  return false;
};
