import alexGruber from '../../assets/wallpapers/alexGruber.webp';
import tobiasRademacher from '../../assets/wallpapers/tobiasRademacher.webp';
import lucaMicheli1 from '../../assets/wallpapers/lucaMicheli1.webp';
import jaromirKalina from '../../assets/wallpapers/jaromirKalina.webp';
import lucaMicheli2 from '../../assets/wallpapers/lucaMicheli2.webp';
import johnFowler from '../../assets/wallpapers/johnFowler.webp';
import zoltanTasi from '../../assets/wallpapers/zoltanTasi.webp';
import mikeYukhtenko from '../../assets/wallpapers/mikeYukhtenko.webp';
import joshuaSortino from '../../assets/wallpapers/joshuaSortino.webp';
import dylanTaylor from '../../assets/wallpapers/dylanTaylor.webp';
import jasonOrtego from '../../assets/wallpapers/jasonOrtego.webp';
import jasonHudson from '../../assets/wallpapers/jasonHudson.webp';
import op23 from '../../assets/wallpapers/op23.webp';
import kellenRiggin from '../../assets/wallpapers/kellenRiggin.webp';
import maxBuergi from '../../assets/wallpapers/maxBuergi.webp';

export const wallpapers: {
  artist: {
    name: string;
    url: string;
  };
  src: string;
}[] = [
  {
    artist: {
      name: 'Alex Gruber',
      url: 'https://unsplash.com/@alex_gruber',
    },
    src: alexGruber,
  },
  {
    artist: {
      name: 'Tobias Rademacher',
      url: 'https://unsplash.com/@tobbes_rd',
    },
    src: tobiasRademacher,
  },
  {
    artist: {
      name: 'Luca Micheli',
      url: 'https://unsplash.com/@lucamicheli',
    },
    src: lucaMicheli1,
  },
  {
    artist: {
      name: 'Jaromír Kalina',
      url: 'https://unsplash.com/@kalinajaromir',
    },
    src: jaromirKalina,
  },
  {
    artist: {
      name: 'Luca Micheli',
      url: 'https://unsplash.com/@lucamicheli',
    },
    src: lucaMicheli2,
  },
  {
    artist: {
      name: 'John Fowler',
      url: 'https://unsplash.com/@wildhoneyi',
    },
    src: johnFowler,
  },
  {
    artist: {
      name: 'Zoltan Tasi',
      url: 'https://unsplash.com/@zoltantasi',
    },
    src: zoltanTasi,
  },
  {
    artist: {
      name: 'Mike Yukhtenko',
      url: 'https://unsplash.com/@yamaicle',
    },
    src: mikeYukhtenko,
  },
  {
    artist: {
      name: 'Joshua Sortino',
      url: 'https://unsplash.com/@sortino',
    },
    src: joshuaSortino,
  },
  {
    artist: {
      name: 'Dylan Taylor',
      url: 'https://unsplash.com/@dyltayl',
    },
    src: dylanTaylor,
  },
  {
    artist: {
      name: 'Jason Ortego',
      url: 'https://unsplash.com/@jasonortego',
    },
    src: jasonOrtego,
  },
  {
    artist: {
      name: 'Jason Hudson',
      url: 'https://unsplash.com/@hudsonj142',
    },
    src: jasonHudson,
  },
  {
    artist: {
      name: 'op23',
      url: 'https://unsplash.com/@op23',
    },
    src: op23,
  },
  {
    artist: {
      name: 'Kellen Riggin',
      url: 'https://unsplash.com/@kalaniparker',
    },
    src: kellenRiggin,
  },
  {
    artist: {
      name: 'Max Bürgi',
      url: 'https://unsplash.com/@maxbuergi',
    },
    src: maxBuergi,
  },
];
