import React from 'react';
import Button from '../../UI/Button';

type Props = {
  handleSecondary?: () => void;
  handlePrimary?: () => void;
  primaryText?: string;
  secondaryText?: string;
};
export const CTA = ({
  handlePrimary = () => {},
  handleSecondary = () => {},
  secondaryText,
  primaryText,
}: Props) => {
  const onSecondaryClick = () => {
    handleSecondary();
  };

  const onPrimaryClick = () => {
    handlePrimary();
  };

  return (
    <div className="mt-32 flex justify-end gap-[16px]">
      {secondaryText && (
        <Button onClick={onSecondaryClick} size="m" text={secondaryText} type="secondary" />
      )}
      {primaryText && (
        <Button onClick={onPrimaryClick} size="m" text={primaryText} type="primary" />
      )}
    </div>
  );
};

CTA.defaultProps = {
  handlePrimary: () => {},
  handleSecondary: () => {},
  primaryText: null,
  secondaryText: null,
};
