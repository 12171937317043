import React from 'react';
import { specials } from '../../../common/constants/regex';
import { Icon } from '../../Icon';

const escapeRegExp = (str: string) => {
  /**
   * Choose to escape every character with '\'
   * even though only some strictly require it when inside of []
   */
  const regex = RegExp(`[${specials.join('\\')}]`, 'g');

  return str.replace(regex, '\\$&');
};

const convertQueryToRegex = (query: string) => {
  /** remove all whitespace */
  let regex = query.replace(' ', '');

  /**
   * escape, as creating a RegExp from the '*' character,
   * or any other character reserved for Regex would not work
   */
  regex = escapeRegExp(regex);

  /**
   * allow up to one whitespace between characters for the following cases
   *
   * search: 'battle f'
   * match: 'BATTLEField' <- is matched although original search contains a space
   *
   * search: 'battlef'
   * match: 'BATTLE Field' <- is matched although original search contains no space
   */
  /** only add whitespace between characters that aren't reserved for regex */
  regex = regex.split(RegExp(`(?=[^${specials.join('\\')}])`)).join(' ?');

  return new RegExp(`(${regex})`, 'gi');
};

type SuggestionProps = {
  item: string;
  query: string;
  showEngine?: boolean;
};

export const Suggestion = ({ item, query, showEngine }: SuggestionProps) => {
  const regex = convertQueryToRegex(query);

  return (
    <a
      href={`https://askvoid.com?q=${encodeURIComponent(item)}`}
      className="relative flex h-[56px] cursor-pointer items-center gap-[8px] overflow-hidden rounded-[12px] p-12 hover:bg-gray-50"
    >
      <Icon path="ui/SearchLine" className="h-[18px] w-[18px]" />

      <p className="group flex items-center whitespace-pre text-text-md font-medium text-gray-400">
        {/** https://github.com/facebook/react/issues/3386#issuecomment-564630693 */}
        {item.split(regex).map((part) =>
          part.match(regex) ? (
            <span className="text-base-gray" key={part}>
              {part}
            </span>
          ) : (
            part
          ),
        )}
        {showEngine && ` - Slice Search`}
      </p>
    </a>
  );
};

Suggestion.defaultProps = {
  showEngine: false,
};
