import { ITabImpression } from './getLastTabs';

const timeToLastTab = (tabImpressions: ITabImpression[]): number => {
  if (tabImpressions.length === 0) {
    return +new Date();
  }

  const lastTabImpression = tabImpressions.reduce((previous, current) => {
    if (previous.unix > current.unix) {
      return previous;
    }
    return current;
  });

  return +new Date() - lastTabImpression.unix;
};

export default timeToLastTab;
