import React, { useEffect, useState } from 'react';
import sliceLogoLineBaseWhite from '../../assets/icons/sliceLogoLineBaseWhite.svg';
import fiatFormatter, { cryptoFormatter } from '../../utils/formatters';

export const Earnings = () => {
  const [balance, setBalance] = useState(0);
  const [rate, setRate] = useState(0);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  useEffect(() => {
    async function fetchEarnings() {
      const jwt = localStorage.getItem('jwt');

      if (!jwt) {
        return;
      }

      const localBalance = localStorage.getItem('balance') ?? 0;

      if (localBalance && Number(localBalance) > 0) {
        setBalance(Number(localBalance));
      }

      const localRate = localStorage.getItem('rate') ?? 0;
      if (localRate && Number(localRate) > 0) {
        setRate(Number(localRate));
      }

      const [balanceResponse, quoteResponse] = await Promise.all([
        fetch('https://api.addslice.com/v1/users/me/balance', {
          headers: {
            Authorization: `Bearer ${jwt}`,
          },
        }),
        fetch('https://api.addslice.com/v1/quotes/usd/btc'),
      ]);

      const slices = Math.round((await balanceResponse.json()).data.slices * 10) / 10;
      const quote = (await quoteResponse.json()).data;

      setBalance(slices);
      localStorage.setItem('balance', String(slices));
      setRate(quote.rate);
      localStorage.setItem('rate', String(quote.rate));
    }
    fetchEarnings();
  }, []);

  return (
    <div className="fixed left-[16px] top-[16px] flex flex-col">
      {balance !== 0 && (
        <>
          <div className="inline-flex items-center gap-[8px]">
            <h1 className="text-display-md font-bold text-white">{balance}</h1>
            <img src={sliceLogoLineBaseWhite} alt="" />
          </div>
          <h1 className="text-text-md font-medium text-white">
            {fiatFormatter.format(balance * 0.001)} ·{' '}
            {cryptoFormatter
              .format(balance * 0.001 * rate * 100000000)
              .replace('BTC', '')
              .trim()}
            {balance * 0.001 * rate * 100000000 === 1 ? 'Sat' : ' Sats'}
          </h1>
        </>
      )}
    </div>
  );
};
