/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

import React from 'react';
import * as Tooltip from '@radix-ui/react-tooltip';
import lockOpenLineBaseDayWhite from '../../../assets/icons/lockOpenLineBaseDayWhite.svg';
import lockClosedLineBaseDayWhite from '../../../assets/icons/lockClosedLineBaseDayWhite.svg';
import useGetConsentPreferences from '../../../utils/hooks/useGetConsentPreferences';
import Button from '../../UI/Button';

export const ConsentButton = () => {
  const { consentPurposes } = useGetConsentPreferences();

  const className = `flex h-[56px] cursor-pointer items-center justify-center rounded-[12px] bg-base-white/[.24] p-[16] text-white`;

  return (
    <>
      {/* <IubendaConsent forceLoad={forceLoad} /> */}
      <Tooltip.Provider>
        <Tooltip.Root delayDuration={0}>
          <Tooltip.Trigger asChild>
            <span>
              <Button
                icon={
                  consentPurposes && consentPurposes[5]
                    ? lockClosedLineBaseDayWhite
                    : lockOpenLineBaseDayWhite
                }
                type={undefined}
                size="l"
                text="Privacy"
                custom={className}
                onClick={() => {
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  // @ts-expect-error
                  window.googlefc.callbackQueue.push(window.googlefc.showRevocationMessage);
                }}
              />
            </span>
          </Tooltip.Trigger>
          <Tooltip.Portal>
            <Tooltip.Content
              className="tooltip"
              sideOffset={8}
              side="bottom"
              avoidCollisions
              collisionPadding={24}
            >
              <p>
                {!consentPurposes || !consentPurposes[5]
                  ? 'Click here to adjust privacy preferences and earn more Slices for allowing third parties to advertise to you.'
                  : 'You are all set to earn more with personalized ads.'}
              </p>
              <Tooltip.Arrow />
            </Tooltip.Content>
          </Tooltip.Portal>
        </Tooltip.Root>
      </Tooltip.Provider>
    </>
  );
};
