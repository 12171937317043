import { useContext, useEffect, useState } from 'react';
import { ITile } from '../../components/Tiles/Tile/Tile';
import BookmarksContext from '../../context/BookmarksContext';
import { getLocal } from '../localStorage';

const useGetUserTiles = () => {
  const bookmarks = useContext(BookmarksContext);
  const [items, setItems] = useState<ITile[]>([]);

  useEffect(() => {
    const getItems = async () => {
      let tiles: ITile[] = [];

      try {
        tiles = getLocal('userTiles');
      } catch (error) {
        /** do nothing */
      }

      setItems(tiles);
    };

    getItems();
  }, [bookmarks]);

  return items;
};

export default useGetUserTiles;
