import React from 'react';

type Props = {
  src: string;
};

export const FaviconBackground = ({ src }: Props) => (
  <div
    style={{ backgroundImage: `url("${src}")` }}
    className={`
      ${
        /**
         * cross-browser background blur solution
         * as firefox has a different implementation for blur
         */ ''
      }
      absolute h-[60px] w-[60px]
      ${/* use z-0, or Dots element is affected by blur */ ''}
      z-0
      scale-[8] bg-[length:2px_2px] bg-repeat
      ${/* apply filters to get desired lightness of background */ ''}
      opacity-20 blur-[1px] saturate-[2]
    `}
  />
);
