import React from 'react';
import { useRecoilState } from 'recoil';
import addModalState from '../../../../utils/atoms/addModalAtom';
import { Icon } from '../../../Icon';

/** allows the user to add new Tiles */
export const NewTile = () => {
  const [, setOpen] = useRecoilState(addModalState);

  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={() => setOpen(true)}
      className=" group relative inline-flex  cursor-pointer flex-col  items-center"
    >
      {/* Logo */}
      <div className="relative flex h-[58px] w-[58px] items-center   justify-center  rounded-[12px] border-[2px] border-dashed border-white bg-[#FFFFFF3D] backdrop-blur-[2px]">
        <div className="relative flex h-full w-full items-center justify-center overflow-hidden rounded-[12px]">
          <Icon path="ui/PlusLine" className="h-[24px] w-[24px] text-base-white" />
        </div>
      </div>
      <p className="pt-[9px] text-center text-text-sm font-medium text-white drop-shadow-textShadow">
        Add bookmark
      </p>
    </div>
  );
};
