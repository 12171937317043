import React from 'react';
import { useRecoilState } from 'recoil';
import Input from '../../UI/Input';
import inputErrorAtom from '../../../utils/atoms/inputErrorsAtom';

type Props = {
  setNewName: React.Dispatch<React.SetStateAction<string>>;
  setNewUrl: React.Dispatch<React.SetStateAction<string>>;
  namePlaceholder: string;
  urlPlaceholder: string;
  nameValue: string;
  urlValue: string;
  handleUpdate: () => void;
};
export const Inputs = ({
  setNewName,
  namePlaceholder,
  urlPlaceholder,
  setNewUrl,
  urlValue,
  nameValue,
  handleUpdate,
}: Props) => {
  const handleChange = (
    e: { currentTarget: { value: React.SetStateAction<string> } },
    setState: React.Dispatch<React.SetStateAction<string>>,
  ) => {
    setState(e.currentTarget.value);
  };

  const [error] = useRecoilState(inputErrorAtom);
  return (
    <>
      <Input
        placeholder={namePlaceholder}
        disabled={false}
        error={error}
        label="Name"
        type="text"
        value={nameValue}
        onChange={(e) => handleChange(e, setNewName)}
        handleUpdate={handleUpdate}
      />

      {/* Input 2 */}
      <div className="mt-12">
        <Input
          placeholder={urlPlaceholder}
          disabled={false}
          error={error}
          value={urlValue}
          label="URL"
          type="text"
          onChange={(e) => handleChange(e, setNewUrl)}
          handleUpdate={handleUpdate}
        />
      </div>
    </>
  );
};
