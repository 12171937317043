import React, { useEffect, useRef, useState } from 'react';
import { Suggestion } from './Suggestion';

import { SponsoredSuggestion, ISuggestion } from './SponsoredSuggestion/SponsoredSuggestion';
import { Icon } from '../Icon';

interface ISuggestions {
  /**
   * we want to store the query in this Object,
   * as the user might have typed a new query, while the suggestion is fetched from the API
   *
   * and the new query might not match the old suggestions from the API
   */
  query: string;
  unix: number;
  items: string[];
}

interface ISponsoredSuggestios {
  unix: number;
  items: ISuggestion[];
}

export const SearchBar = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [suggestions, setSuggestions] = useState<ISuggestions>({
    query: '',
    unix: +new Date(),
    items: [],
  });
  const [sponsoredSuggestions, setSponsoredSuggestions] = useState<ISponsoredSuggestios>({
    unix: +new Date(),
    items: [],
  });

  const handleSearch = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key !== 'Enter') {
      return;
    }

    const url = `https://askvoid.com?q=${encodeURIComponent(searchQuery)}`;

    if (e.ctrlKey) {
      window.open(url, '_blank');
      setSearchQuery('');
      return;
    }

    window.location.href = url;
  };

  useEffect(() => {
    /** reset the suggestions when query is empty */
    if (searchQuery.length < 3) {
      setSuggestions({ query: '', unix: +new Date(), items: [] });
      setSponsoredSuggestions({ unix: +new Date(), items: [] });
    }
  }, [searchQuery]);

  const searchBarRef = useRef<HTMLInputElement>(null);

  return (
    <div className="relative flex w-full flex-col ">
      <div className="relative flex h-[56px] items-center gap-[20px]  ">
        <Icon path="ui/SearchLine" className="absolute mx-20 h-[20px] w-[20px] text-gray-500" />

        <input
          ref={searchBarRef}
          type="text"
          value={searchQuery}
          onKeyUpCapture={(e) => handleSearch(e)}
          onChange={(e) => setSearchQuery(e.currentTarget.value)}
          placeholder="What are you looking for today?"
          className="
            !focus:border-transparent mx-auto flex h-full w-full items-center rounded-[56px] border-[1px] border-gray-200 bg-white px-56 text-text-md font-medium shadow-searchBarBoxShadow
            placeholder:text-gray-500 hover:shadow-inputBoxShadowHover focus:shadow-inputBoxShadowFocus focus:outline-none
            active:outline-none
          "
        />
      </div>

      {/* Suggestions Body */}
      {searchQuery && (
        <div className="absolute left-0 top-[70px] z-[40] w-[100%]">
          <div className="w-full rounded-[12px] bg-white">
            {sponsoredSuggestions.items.length > 0 && (
              <div className="grid w-full grid-cols-3 items-center gap-[8px] border-b-[1px] border-gray-200 p-12">
                {sponsoredSuggestions.items.map((item) => (
                  <SponsoredSuggestion item={item} key={item.publisher.host} />
                ))}
              </div>
            )}

            <span className="flex flex-col p-12">
              <Suggestion item={searchQuery} query={searchQuery} showEngine />
              {suggestions.items.map((item) => (
                <Suggestion item={item} query={suggestions.query} key={item} />
              ))}
            </span>
          </div>
        </div>
      )}
    </div>
  );
};
