import { dayInMs } from '../common/constants/time';
import { ITabImpression } from './getLastTabs';
import { getLocal, setLocal } from './localStorage';

const storeTabImpression = async (id: string) => {
  let tabImpressions: ITabImpression[] = [];

  try {
    tabImpressions = getLocal('tabImpressions');
  } catch (error) {
    /** do nothing */
  }

  /** filter out impressions older than 24h */
  tabImpressions = tabImpressions.filter(
    (impression) => Math.abs(+new Date() - impression.unix) <= dayInMs,
  );

  tabImpressions.push({
    id,
    unix: +new Date(),
  });

  setLocal({ tabImpressions });
};

export default storeTabImpression;
