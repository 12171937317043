import React from 'react';
import { Heading } from '../Heading';

type Props = {
  children: React.ReactNode;
  headingText: string;
  open: boolean;
  canClose: boolean;
  handleClose: () => void;
  illustration?: any;
  blur?: boolean;
};

export const ModalWrapper = ({
  children,
  headingText,
  handleClose,
  open,
  canClose,
  illustration,
  blur,
}: Props) => (
  <div className={`${open ? 'block' : 'hidden'}`}>
    <div className="fixed left-[50%] top-[50%] z-40 w-[calc(100%-32px)] max-w-[554px] translate-x-[-50%] translate-y-[-50%] rounded-[12px] bg-white p-32">
      {illustration && (
        <div className="mb-32 rounded-[12px] bg-gray-50">
          <img src={illustration} alt={`${illustration}`} />
        </div>
      )}

      {/* Heading */}
      <Heading setOpen={handleClose} canClose={canClose} text={headingText} />

      {/* Content */}
      {children}
    </div>
    {/* Modal Overlay */}
    {/* eslint-disable-next-line max-len */}
    {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
    <div
      onClick={() => handleClose()}
      className={`
        fixed left-0 top-0 z-30 h-[100vh] w-full
        ${blur ? 'backdrop-blur-sm' : 'bg-base-gray opacity-80'}
      `}
    />
  </div>
);

ModalWrapper.defaultProps = {
  blur: false,
  illustration: null,
};
