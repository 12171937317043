import { getLocal } from './localStorage';

export interface ITabImpression {
  id: string;
  unix: number;
}

const getLastTabs = (): ITabImpression[] => {
  try {
    return getLocal('tabImpressions');
  } catch (_) {
    return [];
  }
};

export default getLastTabs;
