import React from 'react';

export default React.createContext({
  bookmarks: [],
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  updateBookmark: (payload: { id: number; newName: string; newUrl: string }) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  deleteBookmark: (id: number) => {},
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  addBookmark: (payload: { newName: string | undefined; newUrl: string | undefined }) => {},
});
