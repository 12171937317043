import React from 'react';
import { ModalWrapper } from '../../Modal/ModalWrapper';
import loginCtaIllustration from '../../../assets/images/loginCtaIllustration.webp';
import { CTA } from '../../Modal/CTA';

export const LoginModal = () => (
  <ModalWrapper
    headingText="Link your Slice account"
    open
    illustration={loginCtaIllustration}
    handleClose={() => {}}
    blur
    canClose={false}
  >
    <p className="pt-8 text-text-sm font-medium text-gray-600">
      You will need to create a Slice account to use the Slice New Tab. This allows us to send your
      rewards directly to you!
    </p>

    <CTA
      handleSecondary={() => {
        window.location.href = 'https://addslice.com/tab/auth';
      }}
      handlePrimary={() => {
        window.location.href = 'https://addslice.com/signup?to=https://addslice.com/tab/auth';
      }}
      primaryText="Sign Up"
      secondaryText="Log In"
    />
  </ModalWrapper>
);
