import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { A11y, Navigation, Pagination, Scrollbar } from 'swiper';
import log from 'loglevel';
import { useRecoilValue } from 'recoil';
import { nanoid } from 'nanoid';
import { TilesSlide } from '../TilesSlide';
import 'swiper/css';
import useGetUserTiles from '../../../utils/hooks/useGetUserTiles';
import isAdVisible from '../../../utils/isAdVisible';
import tabIdAtomState from '../../../utils/atoms/tabIdAtom';
import { isAllowedToSeeTiles } from '../../../utils/isAllowedToSeeTiles';
import { TAB_CONFIG } from '../../../common/configs/tab.config';

export const TilesSwiper = () => {
  const sponsoredTiles: any[] = [];
  const userTiles = useGetUserTiles();
  const tabId = useRecoilValue(tabIdAtomState);
  const allTiles = [...sponsoredTiles, ...userTiles];
  const [seen, setSeen] = useState(false);

  useEffect(() => {
    if (sponsoredTiles.length < 1) {
      return;
    }

    sponsoredTiles.forEach((tile) => {
      if (tile.impressionTracking && tile.impressionTracking.length > 0) {
        fetch(tile.impressionTracking[0].url);
      }
    });

    if (seen) {
      return;
    }

    const sponsoredTile = [...sponsoredTiles].shift();

    if (!sponsoredTile) {
      return;
    }

    let tileNode: HTMLDivElement | null = null;
    try {
      tileNode = document.querySelector(`.${sponsoredTile.id}`);
    } catch {
      // do nothing
    }

    if (tileNode === null) {
      return;
    }

    isAllowedToSeeTiles(tabId)
      .then((isAllowed) => {
        if (!isAllowed) {
          throw new Error('limit');
        }
      })
      .then(() => isAdVisible(tileNode))
      .then(() => {
        setTimeout(() => {
          setSeen(true);

          fetch('https://api.addslice.com/v1/tabs/me/transactions', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('jwt')}`,
            },
            body: JSON.stringify([
              {
                format: 'tile',
                id: nanoid(5),
                tab: {
                  id: tabId,
                },
                unix: +new Date(),
              },
            ]),
          });
        }, TAB_CONFIG.minTimeAdVisible);
      })
      .catch((e) => {
        log.debug(e);
      });
  }, [sponsoredTiles]);

  return (
    <Swiper
      className={`
        flex w-full max-w-[456px] items-center justify-center pt-40
        ${/** set a fixed height to prevent jumps in layout when a second page is added */ ''} 
        h-[300px]
      `}
      spaceBetween={40}
      modules={[Navigation, Pagination, Scrollbar, A11y]}
      slidesPerView={1}
      pagination={{ clickable: true }}
    >
      <SwiperSlide>
        <TilesSlide tiles={allTiles.slice(0, 8)} />
      </SwiperSlide>

      {allTiles?.length >= 8 && allTiles?.length <= 32 && (
        <SwiperSlide>
          <TilesSlide tiles={allTiles.slice(8, 16)} />
        </SwiperSlide>
      )}

      {allTiles?.length >= 16 && allTiles?.length <= 32 && (
        <SwiperSlide>
          <TilesSlide tiles={allTiles.slice(16, 32)} />
        </SwiperSlide>
      )}
    </Swiper>
  );
};
