export const cryptoFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'BTC',
  maximumFractionDigits: 0,
});

const fiatFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
});

export default fiatFormatter;
