import React, { useEffect, useState } from 'react';
// import { useRecoilValue } from 'recoil';
// import { EMessageType } from "../../../types/message";
import posthog from 'posthog-js';
import HolyTime from 'holy-time';
import { ActionModal } from './ActionModal';
// import tabIdAtomState from '../../../utils/atoms/tabIdAtom';
import { Dots } from './Dots';
import { Favicon } from './Favicon';

export interface ITile {
  id: number;
  name: string;
  url: string;
  favicon?: string;
  unix: number;
}

export const Tile = ({ id, url, unix, name, favicon }: ITile) => {
  const [faviconSrc, setFaviconSrc] = useState('');
  const [cleanUrl, setCleanUrl] = useState('');
  // const tabId = useRecoilValue(tabIdAtomState);

  useEffect(() => {
    if (favicon) {
      setFaviconSrc(favicon);
      setCleanUrl(url);
      return;
    }

    try {
      /** add https://, or new URL() will fail */
      if (!url.startsWith('https') && !url.startsWith('http')) {
        // eslint-disable-next-line no-param-reassign
        url = `https://${url}`;
      }

      setCleanUrl(url);

      const urlObject = new URL(url);
      setFaviconSrc(`https://icon.horse/icon/${urlObject.hostname.replace('www.', '')}`);
    } catch (_) {
      /** use FallbackIcon */
    }
  }, []);

  return (
    <div className="group relative inline-flex max-w-min flex-col items-center">
      <a
        href={cleanUrl}
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        onClick={(event) => {
          // event.preventDefault();
          // let type = EMessageType.sameTab;
          // if (event.ctrlKey || event.metaKey) {
          //   type = EMessageType.newTab;
          // }
          // chrome.runtime.sendMessage({
          //   type,
          //   data: {
          //     url: cleanUrl,
          //   },
          // });
          /** track clicks for sponsored Tiles */
          if (favicon) {
            posthog.capture('user_clicked_tile');
            const jwt = localStorage.getItem('jwt');
            if (jwt) {
              const { userUuid } = JSON.parse(atob(jwt.split('.')[1]));
              posthog.identify(`u${userUuid}`);
            }
            const clicks = JSON.parse(localStorage.getItem('tileClicks') ?? '[]') as number[];
            const clicksToday = clicks.filter(
              (click) => click > HolyTime.now().subtract(1, 'days').getUnixTime(),
            );
            clicksToday.push(HolyTime.now().getUnixTime());
            localStorage.setItem('tileClicks', JSON.stringify(clicksToday));
          }
          // const data: IAdClickBody = {
          //   tabId,
          //   format: 'tile',
          //   unix: Math.round(+new Date() / 1000),
          // };
          // chrome.runtime.sendMessage({
          //   type: EMessageType.adClick,
          //   data,
          // });
        }}
        className={`
        group relative inline-flex max-w-min cursor-pointer flex-col items-center
        ${/* add the id as class for sponsoredTiles to be able to querySelect them */ ''}
        ${favicon && id}
      `}
      >
        {/* Logo */}
        <Favicon name={name} favicon={faviconSrc} />

        {/* Bookmark name */}
        <p className="max-w-[80px] truncate pt-[9px] text-center text-text-sm font-medium text-white drop-shadow-textShadow">
          {name}
        </p>
      </a>

      {/* Dots */}
      {!favicon && <Dots name={name} url={url} id={id} unix={unix} />}

      {/* Small Modal */}
      <ActionModal id={id} />
    </div>
  );
};

Tile.defaultProps = {
  favicon: null,
};
