import React from 'react';
import { useRecoilState } from 'recoil';
import dotsLineBaseWhite from '../../../../assets/icons/dotsLineBaseWhite.svg';
import actionModalState from '../../../../utils/atoms/actionModalAtom';
import selectedBookmarkState from '../../../../utils/atoms/selectedBookmarkAtom';

type Props = {
  id: number;
  name: string;
  url: string;
  unix: number;
};

export const Dots = ({ id, url, unix, name }: Props) => {
  const [open, setOpen] = useRecoilState(actionModalState);
  const [bookmark, setBookmark] = useRecoilState(selectedBookmarkState);

  const handleOpen = () => {
    setOpen(!open);
    setBookmark({
      id,
      name,
      url,
      unix,
    });
  };
  return (
    // eslint-disable-next-line max-len
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      onClick={() => handleOpen()}
      className={`
        absolute
        right-[-15px] top-[-15px] flex h-[32px] w-[32px] cursor-pointer items-center justify-center rounded-[12px] bg-blurBg opacity-0 backdrop-blur-[12px] transition-all delay-100 ease-in-out group-hover:opacity-100
        ${open && bookmark.id === id && 'opacity-100'}
      `}
    >
      <img src={dotsLineBaseWhite} alt="dots" />
    </div>
  );
};
