import { getLocal } from './localStorage';
import globals from './globals';
import timeToLastTab from './timeToLastTab';
import { TAB_CONFIG } from '../common/configs/tab.config';
import getLastTabs from './getLastTabs';
import { isReload } from './isReload';
import { secondInMs } from '../common/constants/time';

export const isAllowedToSeeTiles = async (tabId: string) => {
  if (window.navigator.onLine === false) {
    return false;
  }

  if (isReload()) {
    return false;
  }

  let tabImpressions = await getLastTabs();

  /** filter out the current impression */
  tabImpressions = tabImpressions.filter((impression) => impression.id !== tabId);

  if (timeToLastTab(tabImpressions) < TAB_CONFIG.minTimeBetweenTabsToShowAds) {
    return false;
  }

  const tabImpressionsInLastMinute = tabImpressions.filter(
    (impression) => Math.abs(+new Date() - impression.unix) <= 30 * secondInMs,
  );

  if (tabImpressionsInLastMinute.length >= TAB_CONFIG.maxTabsInHalfAMinute) {
    return false;
  }

  let impressions: any[] = [];
  try {
    impressions = getLocal('impressions');
  } catch (e) {
    /** do nothing */
  }

  /** verify if allowed to earn for these tiles */
  /** 60000ms * 60minutes * 24hours = 24hours */
  const tileImpressionsToday = impressions.filter(
    (impression) =>
      Math.abs(+new Date() - impression.unix) <= 60000 * 60 * 24 && impression.format === 'tile',
  );

  if (tileImpressionsToday.length >= globals.adLimits.tile) {
    return false;
  }

  return true;
};
