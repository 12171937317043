import React from 'react';
import { Tile, ITile } from '../Tile/Tile';
import { NewTile } from '../Tile/NewTile';
import { SponsoredTileType } from '../../../utils/hooks/useGetSponsoredTiles';

type Props = {
  tiles: (SponsoredTileType | ITile)[];
};

export const TilesSlide = ({ tiles }: Props) => (
  <div className="grid grid-cols-4 grid-rows-2 justify-items-center gap-y-[24px]">
    {tiles?.map((tile) => (
      <Tile
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        id={tile?.id}
        key={tile?.id}
        url={tile?.url}
        name={tile?.name}
        unix={tile?.unix}
        favicon={tile?.favicon}
      />
    ))}
    {tiles?.length < 8 && <NewTile />}
  </div>
);
