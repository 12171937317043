import React from 'react';
import { Icon } from '../../Icon';

type Props = {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};
export const SettingsButton = ({ setOpen, open }: Props) => (
  // eslint-disable-next-line max-len
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
  <div
    onClick={() => {
      setOpen(!open);
    }}
    className="flex h-[56px] w-[56px] cursor-pointer items-center justify-center rounded-[12px] bg-base-white/[.24] p-16 hover:bg-base-white/[.30]"
  >
    <Icon path="ui/SettingsLine" className="h-[24px] w-[24px] text-base-white" />
  </div>
);
