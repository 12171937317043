import React from 'react';
import { SetterOrUpdater } from 'recoil';
import { Icon } from '../../Icon';

type Props = {
  setOpen: SetterOrUpdater<boolean>;
  text: string;
  canClose: boolean;
};
export const Heading = ({ setOpen, text, canClose }: Props) => (
  <div className="flex items-center justify-between">
    <h1 className="text-text-xl font-semibold text-base-gray">{text}</h1>
    {canClose && (
      // eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
      <span onClick={() => setOpen(false)}>
        <Icon path="ui/CloseLine" className="h-[24px] w-[24px] cursor-pointer text-base-gray" />
      </span>
    )}
  </div>
);
