import { atom } from 'recoil';

const selectedBookmarkState = atom({
  key: 'selectedBookmarkState',
  default: {
    id: 0,
    name: '',
    url: '',
    unix: 0,
  },
});
export default selectedBookmarkState;
