import { useEffect, useState } from 'react';

export default function useGetConsentPreferences() {
  const [consentPurposes, setConsentPurposes] = useState({ 1: true, 5: true });
  const [tcfv2String, setTcfv2String] = useState<string | null>(null);

  const getConsentProperties = async () => {
    let purposes = { 1: true, 5: false };

    try {
      const localPurposes = JSON.parse(localStorage.getItem('consentPurposes') as string);
      if (localPurposes) {
        purposes = localPurposes;
      }
    } catch (_) {
      /* empty */
    }

    setConsentPurposes(purposes);
  };

  const getTcfv2String = async () => {
    let tcfv2: string | null = null;
    try {
      tcfv2 = localStorage.getItem('tcfv2');
    } catch (_) {
      /* empty */
    }

    setTcfv2String(tcfv2);
  };

  useEffect(() => {
    window.addEventListener(
      'consentPurposesUpdate',
      () => {
        getConsentProperties();
      },
      false,
    );

    getConsentProperties();
    getTcfv2String();
  }, []);

  return { consentPurposes, tcfv2String, setConsentPurposes };
}
