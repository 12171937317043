import React, { useEffect, useReducer } from 'react';

import BookmarksContext from './BookmarksContext';
import { DELETE_BOOKMARK, bookmarkReducers, EDIT_BOOKMARK, ADD_BOOKMARK, init } from './reducers';
import { getLocal } from '../utils/localStorage';

type Payload = {
  name: string;
  newUrl: string;
  newName: string;
};

function GlobalState({ children }: { children: React.ReactNode }) {
  const [bookmarksState, dispatch] = useReducer(
    bookmarkReducers,
    {
      bookmarks: [],
    },
    init,
  );
  useEffect(() => {
    const getItems = async () => {
      let res: object[];
      try {
        res = getLocal('userTiles');
      } catch (e) {
        res = [];
      }
      dispatch({
        type: 'reset',
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        payload: res,
      });
    };
    getItems();
  }, []);

  const addBookmark = (payload: Payload) => {
    dispatch({
      type: ADD_BOOKMARK,
      payload,
    });
  };

  const updateBookmark = (payload: Payload) => {
    dispatch({
      type: EDIT_BOOKMARK,
      payload,
    });
  };

  const deleteBookmark = (id: number) => {
    dispatch({ type: DELETE_BOOKMARK, id });
  };

  const values = React.useMemo(
    () => ({
      bookmarks: bookmarksState.bookmarks,
      updateBookmark,
      deleteBookmark,
      addBookmark,
    }),
    [bookmarksState.bookmarks],
  );
  return (
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    <BookmarksContext.Provider value={values}>{children}</BookmarksContext.Provider>
  );
}

export default GlobalState;
