import React, { useEffect, useState } from 'react';
import posthog from 'posthog-js';
import randomIntFromInterval from '../../utils/randomIntFromInterval';
import sliceLogoLineBaseDark from '../../assets/icons/sliceLogoLineBaseDark.svg';
import { wallpapers } from '../../common/constants/wallpapers';

export const Wallpaper = () => {
  const [wallpaper, setWallpaper] = useState<(typeof wallpapers)[0]>();
  const [isLoading, setIsLoading] = useState(true);
  const [isEligibleForVideo, setIsEligibleForVideo] = useState(false);

  useEffect(() => {
    setWallpaper(wallpapers[randomIntFromInterval(0, wallpapers.length - 1)]);
  }, []);

  useEffect(() => {
    async function checkEligibility() {
      const localJWT = localStorage.getItem('jwt');

      if (!localJWT) {
        return;
      }

      const response = await fetch('https://api.addslice.com/v1/callbacks/3b/eligibility', {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localJWT}`,
        },
      });

      const result = await response.json();

      if (result.data.isEligible === true) {
        setIsEligibleForVideo(true);
      }
    }

    checkEligibility();
  }, []);

  if (wallpaper) {
    return (
      <>
        {isEligibleForVideo && (
          <div className="fixed bottom-[16px] left-[16px] z-[10] flex items-center gap-[8px]">
            <a
              className="border-b-[2px] border-transparent text-text-md font-semibold text-gray-200 hover:!border-white hover:!text-white "
              href="https://addslice.com/dashboard/video?utm_source=newtab&utm_campaign=video"
              target="_blank"
              rel="noreferrer"
              onClick={() => {
                posthog.capture('user_clicked_video_cta');
                const jwt = localStorage.getItem('jwt');
                if (jwt) {
                  const { userUuid } = JSON.parse(atob(jwt.split('.')[1]));
                  posthog.identify(`u${userUuid}`);
                }
              }}
            >
              Watch a rewarded video ↗
            </a>
            <div className="mr-auto flex items-center justify-center gap-[2px] rounded-[6px] !bg-primary-100 px-8 py-[2px]">
              {/* hardcoded 20 slices, because US only */}
              <p className="text-text-sm font-semibold text-gray-900">20</p>
              <img src={sliceLogoLineBaseDark} alt="Slice Logo" width="16" height="16" />
            </div>
          </div>
        )}
        <div className="fixed h-full w-full animate-wallpaper-zoom-out">
          {!isLoading && (
            /** only render the gradient when the wallpaper has loaded to prevent flashing */
            <div className="wallpaper-gradient fixed z-10 h-full w-full" />
          )}

          <img
            className={`h-full w-full object-cover ${isLoading && 'opacity-0'}
                ${
                  /**
                   * don't put animate-wallpaper-zoom-out & animate-wallpaper-fade-in in the same expression
                   * eslint will fail!
                   */ ''
                }
                ${!isLoading && 'animate-wallpaper-zoom-out'}
                ${!isLoading && 'animate-wallpaper-fade-in'}`}
            alt="Slice New Tab Wallpaper"
            src={wallpaper.src}
            onLoad={() => {
              setIsLoading(false);
            }}
          />
        </div>
      </>
    );
  }

  return null;
};
