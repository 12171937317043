import { atom } from 'recoil';

type RefreshSponsoredTilesType = {
  key: string;
  default: number | null;
};

const refreshSponsoredTiles = atom({
  key: 'refreshSponsoredTilesState',
  default: null,
} as RefreshSponsoredTilesType);
export default refreshSponsoredTiles;
