export const getLocal = (key: string): any => {
  try {
    const local = localStorage.getItem(key);
    if (!local || local === 'undefined') {
      throw new Error();
    }
    const parsed = JSON.parse(local);
    return parsed;
  } catch (_) {
    throw new Error();
  }
};

type GenericObject = Record<string, unknown>;

export const setLocal = (data: GenericObject): void => {
  try {
    Object.entries(data).forEach(([key, value]) => {
      localStorage.setItem(key, JSON.stringify(value));
    });
    return;
  } catch (_) {
    throw new Error();
  }
};
