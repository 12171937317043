import React, { useEffect, useState } from 'react';
import SHA256 from 'crypto-js/sha256';
import { nanoid } from 'nanoid';
import HolyTime from 'holy-time';
import { useParams } from 'react-router-dom';
import Button from '../components/UI/Button';
import { isReload } from '../utils/isReload';

// const countNodes = (el: Element): number => {
//   let count = 0;
//   for (let i = 0; i < el.children.length; i += 1) {
//     count += countNodes(el.children[i] as HTMLElement);
//   }
//   return count + el.children.length;
// };

const waitForFocus = (): Promise<void> =>
  new Promise((resolve) => {
    const interval = setInterval(() => {
      if (document.hasFocus()) {
        clearInterval(interval);
        resolve();
      }
    }, 500);
  });

const X = () => {
  const { userUuid } = useParams();
  const [showFocusNotification, setShowFocusNotification] = useState(false);

  document.title = 'Slice Ad [live]';
  useEffect(() => {
    const script = document.createElement('script');

    script.src = 'https://securepubads.g.doubleclick.net/tag/js/gpt.js';

    document.head.appendChild(script);
  }, []);

  useEffect(() => {
    const minInterval = HolyTime.duration(30, 'seconds').in('milliseconds');
    const maxInterval = HolyTime.duration(50, 'seconds').in('milliseconds');

    const refreshAds = () => {
      const interval = Math.floor(Math.random() * (maxInterval - minInterval) + minInterval);
      setTimeout(async () => {
        if (!document.hasFocus()) {
          setShowFocusNotification(true);
          await waitForFocus();
          setShowFocusNotification(false);
        }

        // wait 5 seconds before refresh, as the user is looking at ad now
        setTimeout(() => {
          googletag.cmd.push(() => {
            googletag.pubads().refresh();
          });
          refreshAds();
        }, HolyTime.Units.SECOND * 5);
      }, interval);
    };

    refreshAds();
  }, []);

  useEffect(() => {
    window.googletag = window.googletag || { cmd: [] };

    googletag.cmd.push(() => {
      googletag
        .defineSlot(
          '/22668078070/slice-x',
          [
            [1, 1],
            [300, 250],
          ],
          'div-gpt-ad-1716379677074-0',
        )
        ?.addService(googletag.pubads());
      googletag.pubads().enableSingleRequest();
      googletag.enableServices();
    });
    googletag.cmd.push(() => {
      googletag.display('div-gpt-ad-1716379677074-0');
    });
    googletag.cmd.push(() => {
      googletag.pubads().addEventListener('slotRenderEnded', (event) => {
        try {
          const { slot } = event;
          const slotId = slot.getSlotElementId();

          const element = document.querySelector(`[id^="${slotId}"]`);
          // TODO: Check if ad rendered with || element.getAttribute('style') === 'display: none;'
          if (!element) {
            return;
          }

          // const count = countNodes(element);

          // if (count <= 2) {
          //   return;
          // }

          const googleQueryId = element.getAttribute('data-google-query-id');

          const unix = +new Date();
          const id = nanoid(8);
          const digest = SHA256(`${userUuid},${unix},${googleQueryId},${id}`).toString();

          fetch(
            `https://api.addslice.com/v1/x/render?hash=${btoa(
              JSON.stringify({
                uuid: userUuid,
                digest,
                unix,
                googleQueryId,
                id,
              }),
            )}`,
          );

          document.title = `Slice Ad [live] - ${digest}`;
        } catch (error) {
          console.error(error);
        }
      });
    });
  }, []);

  if (isReload()) {
    return null;
  }

  return (
    <>
      {showFocusNotification && (
        <div className="fixed z-10 flex h-full w-full items-center justify-center bg-[rgba(255,255,255,0.7)] backdrop-blur-sm">
          <div className="flex flex-col justify-center gap-4">
            <p className="text-[12px] text-gray-950">Focus the window to continue seeing ads.</p>
            <Button
              type="primary"
              text="Focus"
              size="xs"
              className="px-12 py-4"
              onClick={(e) => {
                e.preventDefault();
              }}
            />
          </div>
        </div>
      )}
      <div className="flex h-[100vh] w-[100vw] gap-[8px] bg-white">
        <div className="relative flex h-[250px] w-[300px]">
          <div
            id="div-gpt-ad-1716379677074-0"
            style={{ minWidth: '300px', minHeight: '250px', zIndex: '1' }}
          />
          <div className="absolute left-0 top-0 z-0 h-full w-full bg-black opacity-[5%]" />
        </div>
      </div>
    </>
  );
};

export default React.memo(X);
