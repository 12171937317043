import { isElementVisible, isDocumentVisible } from './isVisible';

const isAdVisible = (node: any): Promise<boolean> =>
  new Promise((resolve) => {
    const io = new IntersectionObserver(
      (entries) => {
        entries.forEach((event) => {
          if (event.intersectionRatio >= 1) {
            /**
             * firefox bug: "unobserve is not a function"
             * firefox has no unobserve()?, therefore use disconnect
             */
            io.disconnect();

            if (isElementVisible(node) && isDocumentVisible()) {
              resolve(true);
              return;
            }

            // const interval = setInterval(async () => {
            if (!isElementVisible(node) || !isDocumentVisible()) {
              return;
            }

            resolve(true);
          }
        });
      },
      {
        rootMargin: '0px',
        threshold: [0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1],
      },
    );
    io.observe(node);
  });

export default isAdVisible;
