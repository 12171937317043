import { atom } from 'recoil';

const inputErrorAtom = atom({
  key: 'inputErrorAtom',
  default: {
    url: {
      field: 'URL',
      message: '',
    },
    name: {
      field: 'Name',
      message: '',
    },
  },
});
export default inputErrorAtom;
