import validator from 'validator';
import React from 'react';
import { SetterOrUpdater } from 'recoil';

function checkInputs(payload: { newUrl: string; newName: string }) {
  const isValidUrl = validator.isURL(payload.newUrl);
  const isEnteredUrl = payload.newUrl.length >= 1;
  const isLength = payload.newUrl.length <= 500;
  const isNameEntered = payload.newName.length >= 1 && payload.newName.length <= 250;
  const validUrl = isValidUrl && isLength && isEnteredUrl;
  return { validUrl, isNameEntered };
}

export function handleUpdate(
  payload: any,
  bookmarkFn: any,
  setUrl: React.Dispatch<React.SetStateAction<string>>,
  setName: React.Dispatch<React.SetStateAction<string>>,
  setOpen: SetterOrUpdater<boolean>,
  setError: SetterOrUpdater<{
    url: { field: string; message: string };
    name: { field: string; message: string };
  }>,
) {
  const { validUrl } = checkInputs(payload);
  const { isNameEntered } = checkInputs(payload);
  if (validUrl && isNameEntered) {
    bookmarkFn(payload);
    setUrl('');
    setName('');
    setOpen(false);
    setError({
      url: {
        field: 'URL',
        message: '',
      },
      name: {
        field: 'Name',
        message: '',
      },
    });
  }
  if (!isNameEntered) {
    setError({
      url: {
        field: 'URL',
        message: '',
      },
      name: {
        field: 'Name',
        message: 'Enter a valid Name',
      },
    });
  }
  if (!validUrl) {
    setError({
      url: {
        field: 'URL',
        message: 'Enter a valid Url',
      },
      name: {
        field: 'Name',
        message: '',
      },
    });
  }
  if (!validUrl && !isNameEntered) {
    setError({
      url: {
        field: 'URL',
        message: 'Enter a valid Url',
      },
      name: {
        field: 'Name',
        message: 'Enter a valid Name',
      },
    });
  }
}
