import React from 'react';

interface IButton {
  type: string | undefined;
  text: string | undefined;
  size: string | undefined;
  isDisabled?: boolean;
  isLoading?: boolean;
  custom?: string;
  icon?: any;
  className?: string;
  onClick: (arg?: any) => any;
}

const Button: React.FC<IButton> = ({
  type,
  isDisabled,
  text,
  custom,
  size,
  isLoading,
  icon,
  className,
  onClick,
}) => {
  /** baseStyle that every button is based on */
  let baseStyle =
    'rounded-[8px] text-text-md font-semibold flex items-center justify-center self-center justify-self-center';

  if (size === 's') {
    baseStyle = `${baseStyle} py-8 px-16`;
  }
  if (size === 'm') {
    baseStyle = `${baseStyle} py-12 px-24`;
  }
  if (size === 'l') {
    baseStyle = `${baseStyle} py-16 px-24`;
  }
  if (size === 'xl') {
    baseStyle = `${baseStyle} py-24 px-40`;
  }
  /** style specific to the type of button, like primary, secondary, tertiary */
  let typeStyle = '';

  if (type === 'primary') {
    typeStyle = 'text-white bg-gray-900 hover:!bg-base-gray active:bg-gray-700';

    if (isDisabled) {
      typeStyle = ` ${typeStyle}  pointer-events-none !bg-gray-300`;
    }
  }

  if (type === 'secondary') {
    typeStyle = '!bg-gray-50 hover:!bg-gray-100';

    if (isDisabled) {
      typeStyle = `${typeStyle} text-gray-400 !bg-gray-50 pointer-events-none`;
    }
  }

  if (type === 'tertiary') {
    typeStyle = '!bg-white text-base-gray hover:!bg-gray-100';

    if (isDisabled) {
      typeStyle = `${typeStyle} text-gray-400 !bg-gray-50 pointer-events-none`;
    }
  }
  if (type === 'danger') {
    typeStyle = '!bg-error-500 text-white hover:!bg-error-dark active:bg-error-veryDark';

    if (isDisabled) {
      typeStyle = `${typeStyle} text-gray-400 !bg-gray-50 pointer-events-none`;
    }
  }
  return (
    <button
      onClick={onClick}
      type="button"
      disabled={isDisabled}
      className={`${baseStyle} ${typeStyle} ${custom || ''} ${className || ''}`}
    >
      {icon && <img width={24} height={24} src={icon} alt="icon" className="mr-8" />}
      {text}
      {isLoading && (
        <div className={`spinner loading ml-4 ${type === 'secondary' ? 'dark' : ''}`}>
          <div />
          <div />
          <div />
        </div>
      )}
    </button>
  );
};

Button.defaultProps = {
  isDisabled: false,
  isLoading: false,
  custom: '',
  icon: null,
};
export default Button;
