import React, { useContext, useState } from 'react';
import { useRecoilState } from 'recoil';
import BookmarksContext from '../../../context/BookmarksContext';
import addModalState from '../../../utils/atoms/addModalAtom';
import { CTA } from '../../Modal/CTA';
import { Inputs } from '../../Modal/Inputs';
import inputErrorAtom from '../../../utils/atoms/inputErrorsAtom';
import { ModalWrapper } from '../../Modal/ModalWrapper';
import { handleUpdate } from '../../../utils/functions';

export const AddTileModal = () => {
  const { addBookmark } = useContext(BookmarksContext);
  const [open, setOpen] = useRecoilState(addModalState);
  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const payload = {
    newName: name,
    newUrl: url,
  };

  const [, setError] = useRecoilState(inputErrorAtom);
  const handleClose = () => {
    setName('');
    setUrl('');
    setOpen(false);
    setError({
      url: { field: '', message: '' },
      name: { field: '', message: '' },
    });
  };
  return (
    <ModalWrapper canClose handleClose={handleClose} headingText="Add Bookmark" open={open}>
      {/* Inputs */}

      {/* Spacing Div */}
      <div className="mb-32" />
      <Inputs
        namePlaceholder="Slice"
        urlPlaceholder="addslice.com"
        setNewName={setName}
        setNewUrl={setUrl}
        nameValue={name}
        urlValue={url}
        handleUpdate={() => handleUpdate(payload, addBookmark, setUrl, setName, setOpen, setError)}
      />

      {/* Modal Button */}
      <CTA
        secondaryText="Cancel"
        handleSecondary={() => handleClose()}
        primaryText="Add"
        handlePrimary={() => handleUpdate(payload, addBookmark, setUrl, setName, setOpen, setError)}
      />
    </ModalWrapper>
  );
};
