import React from 'react';
import { useRecoilState } from 'recoil';
import checkMarkSolidPrimary500 from '../../../assets/icons/checkMarkSolidPrimary500.svg';
import Button from '../../UI/Button';
import checkBorderLineBaseWhite from '../../../assets/icons/checkBorderLineBaseWhite .svg';
import settingsAtom from '../../../utils/atoms/settingsAtom';

type Props = {
  icon: any;
  heading: string;
  tier: number;
  textColor: string;
  bgColor: string;

  price: string;
  features: { text: string }[];
  activeIndex: number | null;
  setActiveIndex: React.Dispatch<React.SetStateAction<number | null>>;
};
export const TierCard = ({
  heading,
  price,
  icon,
  tier,
  features,
  setActiveIndex,
  activeIndex,
  bgColor,
  textColor,
}: Props) => {
  const [, setSettings] = useRecoilState(settingsAtom);
  const updateTier = () => {
    setActiveIndex(tier);
    setSettings({ settings: { tier } });
  };

  return (
    <div className="relative flex w-[320px] min-w-[320px] flex-col rounded-[12px] border-[1px] border-gray-200">
      <div className="flex w-full flex-col items-center justify-center px-32 pt-32">
        <div
          className={`${bgColor} flex h-[60px] w-[60px] items-center justify-center rounded-[12px]`}
        >
          <img src={icon} className="h-[32px] w-[32px]" alt="coin" />
        </div>
        <p className={`${textColor} pt-20 text-text-sm font-semibold`}>{heading}</p>
        <h1 className="text-display-xs font-semibold text-gray-900">{price}</h1>
        <span className="hidden text-primary-500" />
      </div>
      <ul className="flex flex-col gap-[12px] p-32">
        {features.map((item) => (
          <li key={item.text} className="flex w-full items-center gap-[8px]">
            <img src={checkMarkSolidPrimary500} alt="checkMark" className="h-[20px] w-[20px]" />
            <span className="text-text-md font-regular text-gray-600">{item.text}</span>
          </li>
        ))}
      </ul>
      <div className="bottom-0 left-0 mt-auto w-full rounded-b-[12px] bg-gray-50 p-32">
        <Button
          onClick={() => updateTier()}
          size="m"
          icon={activeIndex === tier ? checkBorderLineBaseWhite : ''}
          text={activeIndex === tier ? 'Selected' : 'Select'}
          isDisabled={activeIndex === tier}
          type="primary"
          custom="w-[100%]"
        />
      </div>
    </div>
  );
};
