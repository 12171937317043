import React, { useState } from 'react';

type Props = {
  src: string;
  setError: React.Dispatch<React.SetStateAction<boolean>>;
  error: boolean;
};

export const Icon = ({ src, setError, error }: Props) => {
  const [loaded, setLoaded] = useState(false);

  return (
    <img
      src={src}
      width={34}
      onLoad={() => setLoaded(true)}
      onError={() => setError(false)}
      height={34}
      alt="Favicon"
      className={`z-20 rounded-[100%] opacity-0 transition-all duration-200 ease-in-out ${
        error && 'opacity-0'
      } ${loaded && 'opacity-100'}`}
    />
  );
};
