import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { nanoid } from 'nanoid';
import log from 'loglevel';
import posthog from 'posthog-js';
import { Wallpaper } from '../components/Wallpaper';
import { SearchBar } from '../components/SearchBar';
import { TilesSwiper } from '../components/Tiles/TilesSwiper';
import { EditTileModal } from '../components/Modals/EditTileModal';
import { AddTileModal } from '../components/Modals/AddTileModal';
import tabIdAtomState from '../utils/atoms/tabIdAtom';
import storeTabImpression from '../utils/storeTabImpression';
import { Earnings } from '../components/Earnings';
import refreshJwtState from '../utils/atoms/refreshJwtAtom';
import { LoginModal } from '../components/Modals/LoginModal';
import { Message } from '../common/types/message';
import { Ads } from '../components/Ads';
import { Settings } from '../components/Settings';
import { isReload } from '../utils/isReload';

if (typeof window !== 'undefined') {
  posthog.init('phc_mc5r7ObvLktpTBPnDrrilwQGpApxPpEVnMnyS6jp0t6', {
    api_host: 'https://hog.sli.ce.it',
    capture_pageview: false,
  });
}

function App() {
  const [tabId, setTabId] = useRecoilState(tabIdAtomState);
  const [refreshJwt] = useRecoilState(refreshJwtState);
  const [jwt, setJwt] = useState(null);
  const [isJwtLoading, setIsJwtLoading] = useState(true);
  const [showLoginModal, setShowLoginModal] = useState(true);
  const showBanners = !isJwtLoading && jwt && !isReload();
  const showAuthIframe = !isJwtLoading && !jwt;

  useEffect(() => {
    window.addEventListener('message', (event) => {
      if (event.source !== window) return;

      const message = event.data;

      if (message.message && message.message === Message.passVersionFromContentToWeb) {
        const { version } = message.data;
        log.debug(version);
      }
    });
  }, []);

  useEffect(() => {
    const localJWT = localStorage.getItem('jwt');

    if (!localJWT) {
      return;
    }

    fetch('https://api.addslice.com/v1/users/me/ping', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${localJWT}`,
      },
    });
  }, []);

  useEffect(() => {
    const id = nanoid(5);
    setTabId(id);
    storeTabImpression(id);

    let localJwt = null;
    try {
      localJwt = localStorage.getItem('jwt') as any;
    } catch (e) {
      localStorage.removeItem('jwt');
    }

    if (!localJwt) {
      setJwt(null);
      setShowLoginModal(true);
      setIsJwtLoading(false);
      return;
    }

    setJwt(localJwt);
    setIsJwtLoading(false);
  }, [refreshJwt]);

  return (
    <div className="flex h-[100vh] w-full items-center justify-center">
      <Wallpaper />

      <Earnings />
      <Settings />

      {showAuthIframe && showLoginModal && <LoginModal />}
      {showAuthIframe && (
        <iframe className="hidden" src="https://addslice.com/privacy" title="Auth iframe" hidden />
      )}
      <div
        className={`
        fixed left-[50%]
        top-[50%] mr-[-50%] inline-flex max-w-[calc(100%-32px)] translate-x-[-50%] translate-y-[-50%] flex-col items-center
        justify-center md:w-[664px] md:px-0
      `}
      >
        <SearchBar />
        <TilesSwiper />
      </div>

      <AddTileModal />
      <EditTileModal />
    </div>
  );
}

export default App;
