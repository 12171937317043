import React, { useEffect, useState } from 'react';
import { useRecoilState } from 'recoil';
import { TierCard } from '../TierCard';
import coinDollarSolidPrimary500 from '../../../assets/icons/coinDollarSolidPrimary500.svg';
import bankSolidYellow500 from '../../../assets/icons/bankSolidYellow500.svg';
import safeSolidSalmon500 from '../../../assets/icons/safeSolidSalmon500.svg';
import Button from '../../UI/Button';
import tiersModalState from '../../../utils/atoms/tiersModalAtom';
import { getLocal, setLocal } from '../../../utils/localStorage';
import refreshSponsoredTiles from '../../../utils/atoms/refreshSponsoredTilesAtom';
import globals from '../../../utils/globals';
import refreshBanners from '../../../utils/atoms/refreshBannersAtom';

const data = [
  {
    tier: 1,
    heading: 'Low earnings',
    price: 'Basic',
    icon: coinDollarSolidPrimary500,
    textColor: 'text-primary-500',
    bgColor: 'bg-primary-100',
    features: [{ text: '1 ad' }],
  },
  {
    tier: 2,
    heading: 'Medium earnings',
    price: 'Advanced',
    icon: safeSolidSalmon500,
    textColor: 'text-salmon-500',
    bgColor: 'bg-salmon-100',
    features: [{ text: '1-2 ads on one Tab' }, { text: '4 sponsored Tiles' }],
  },
  {
    tier: 3,
    heading: 'High earnings',
    price: 'Expert',
    icon: bankSolidYellow500,
    textColor: 'text-yellow-500',
    bgColor: 'bg-yellow-100',
    features: [{ text: '1-2 ads on one Tab' }, { text: '8 sponsored Tiles' }],
  },
];
export const TiersModal = () => {
  const [open, setOpen] = useRecoilState(tiersModalState);
  const [selectedTier, setSelectedTier] = useState<number | null>(null);
  // eslint-disable-next-line max-len
  const [, setRefreshSponsoredTilesState] = useRecoilState(refreshSponsoredTiles);
  const [, setRefreshBanners] = useRecoilState(refreshBanners);
  const [reset, setReset] = useState(false);

  const handleUpdateAdPref = async () => {
    setOpen(false);
    try {
      setLocal({
        settings: {
          tier: selectedTier,
        },
      });
      if (typeof selectedTier === 'number') {
        setRefreshSponsoredTilesState(globals.tiers[selectedTier].tiles);
        setRefreshBanners(globals.tiers[selectedTier].banners);
      }
    } catch (_) {
      // empty
    }
  };
  const handleCancel = () => {
    setOpen(false);
    setReset(!reset);
  };

  // Sets default Tier on load
  useEffect(() => {
    const getSettings = async () => {
      try {
        const response = getLocal('settings');
        setSelectedTier(response.tier);
      } catch (_) {
        /* empty */
        setSelectedTier(3);
        setLocal({
          settings: {
            tier: 3,
          },
        });
      }
    };
    getSettings();
  }, [reset]);

  return (
    <div className="w-full p-16 lg:p-0 ">
      <div
        className={` ${
          open ? 'block' : 'hidden'
        } relative z-[50]  mx-auto  max-w-[1056px] rounded-[12px] bg-base-white p-32  `}
      >
        {/*    Heading */}
        <h1 className="text-text-xl font-semibold text-base-gray">Boost your earnings</h1>
        <p className="pb-32  text-text-sm font-regular text-gray-600">
          Control your Slice Tab experience and tailor the amount of ads you want to see.
        </p>
        <div className=" flex w-full gap-[16px] overflow-x-auto ">
          {data.map((item) => (
            <TierCard
              key={item.tier}
              tier={item.tier}
              icon={item.icon}
              heading={item.heading}
              price={item.price}
              textColor={item.textColor}
              bgColor={item.bgColor}
              activeIndex={selectedTier}
              setActiveIndex={setSelectedTier}
              features={item.features}
            />
          ))}
        </div>
        <div className="flex justify-end gap-[16px] pt-32">
          <Button onClick={() => handleCancel()} size="m" text="Cancel" type="secondary" />
          <Button
            onClick={() => handleUpdateAdPref()}
            size="m"
            text="Save Settings"
            type="primary"
          />
        </div>
      </div>

      {/* Overlay */}
      {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,
      jsx-a11y/no-static-element-interactions */}
      <div
        onClick={() => handleCancel()}
        className={`${
          open ? 'fixed' : 'hidden'
        } left-0 top-0 z-[10] h-[100vh] w-full bg-base-gray opacity-80`}
      />
    </div>
  );
};
