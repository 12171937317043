import { secondInMs } from '../constants/time';

export const TAB_CONFIG = {
  minTimeBetweenTabsToShowAds: secondInMs * 5,
  maxTabsInHalfAMinute: 6,
  adLimits: {
    banner: 40,
    click: 10,
  },
  minTimeAdVisible: 1 * secondInMs,
};
