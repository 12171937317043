import React, { useContext, useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import BookmarksContext from '../../../context/BookmarksContext';
import editModalState from '../../../utils/atoms/editModalAtom';
import selectedBookmarkState from '../../../utils/atoms/selectedBookmarkAtom';
import { CTA } from '../../Modal/CTA';
import { Inputs } from '../../Modal/Inputs';
import inputErrorAtom from '../../../utils/atoms/inputErrorsAtom';
import { ModalWrapper } from '../../Modal/ModalWrapper';
import { handleUpdate } from '../../../utils/functions';

export const EditTileModal = () => {
  const { updateBookmark } = useContext(BookmarksContext);
  const selectedBookmark = useRecoilValue(selectedBookmarkState);
  const [newName, setNewName] = useState<string>(selectedBookmark?.name);
  const [newUrl, setNewUrl] = useState<string>(selectedBookmark?.url);
  const [open, setOpen] = useRecoilState(editModalState);

  useEffect(() => {
    setNewUrl(selectedBookmark.url);
    setNewName(selectedBookmark.name);
  }, [selectedBookmark]);

  const payload = {
    id: selectedBookmark.id,
    newName: newName || selectedBookmark.name,
    newUrl: newUrl || selectedBookmark.url,
  };

  const [, setError] = useRecoilState(inputErrorAtom);

  const handleClose = () => {
    setNewUrl('');
    setNewName('');
    setOpen(false);
    setError({
      url: { field: '', message: '' },
      name: { field: '', message: '' },
    });
  };

  return (
    <ModalWrapper canClose headingText="Edit Bookmark" open={open} handleClose={handleClose}>
      {/* Spacing Div */}
      <div className="mb-32" />
      <Inputs
        namePlaceholder=""
        urlPlaceholder=""
        setNewUrl={setNewUrl}
        setNewName={setNewName}
        nameValue={newName}
        urlValue={newUrl}
        handleUpdate={() =>
          handleUpdate(payload, updateBookmark, setNewUrl, setNewName, setOpen, setError)
        }
      />
      {/* Modal Button */}
      <CTA
        secondaryText="Cancel"
        handleSecondary={handleClose}
        primaryText="Save"
        handlePrimary={() =>
          handleUpdate(payload, updateBookmark, setNewUrl, setNewName, setOpen, setError)
        }
      />
    </ModalWrapper>
  );
};
