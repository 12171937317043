import React from 'react';
import { useRecoilState } from 'recoil';
import { SettingsButton } from './SettingsButton';

import { TiersModal } from './TiersModal';
import tiersModalState from '../../utils/atoms/tiersModalAtom';
import { ConsentButton } from './ConsentButton';

export const Settings = () => {
  const [open, setOpen] = useRecoilState(tiersModalState);
  return (
    <>
      <div className="fixed right-[16px] top-[16px] flex gap-[8px]">
        <ConsentButton />
        <SettingsButton setOpen={setOpen} open={open} />
      </div>
      {open && <TiersModal />}
    </>
  );
};
