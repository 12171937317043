import React from 'react';
import errorSolidError500 from '../../assets/icons/errorSolidError500.svg';

export interface IInput {
  placeholder: string;
  disabled: boolean;
  label: string;
  type: string;
  onChange: (arg?: any) => any;
  error: {
    url: { field: string; message: string };
    name: { field: string; message: string };
  };
  value: string;
  handleUpdate: () => void;
}
const Input: React.FC<IInput> = ({
  disabled,
  label,
  onChange,
  type,
  placeholder,
  error,
  value,
  handleUpdate,
}) => {
  const err =
    (label === error.url.field && error.url.message.length > 1) ||
    (label === error.name.field && error.name.message.length > 1);

  const focusBase = 'focus:outline-none active:outline-none ';
  let baseStyle =
    'rounded-[8px] p-[12px_44px_12px_16px] w-full box-border placeholder-gray-500 text-text-md font-medium shadow-inputBoxShadow transition-shadow ease 0.2s';
  baseStyle = disabled
    ? `${baseStyle} bg-gray-50 text-base-gray shadow-inputBoxShadow `
    : `${baseStyle} border-[1px] text-base-gray border-gray-200 `;

  const focusStyle = err
    ? ` ${focusBase} focus:shadow-inputBoxShadowFocusError `
    : `${focusBase} focus:outline-none focus:shadow-inputBoxShadowFocus active:outline-none !focus:border-transparent`;

  let hoverStyle = !disabled ? 'hover:shadow-inputBoxShadowHover' : 'hover:shadow-inputBoxShadow';

  if (err) {
    baseStyle = `${baseStyle} !border-error-500 !border-error-300`;
    hoverStyle = '';
  }
  const handleKeypress = (e: { keyCode: number }) => {
    if (e.keyCode === 13) {
      handleUpdate();
    }
  };
  return (
    <>
      <p className="pb-[6px] text-text-sm font-medium text-base-gray">{label}</p>
      <div className="relative flex w-full items-center">
        <input
          value={value}
          onChange={(e) => onChange(e)}
          className={`${baseStyle} ${hoverStyle} ${focusStyle} `}
          type={type}
          placeholder={placeholder}
          disabled={disabled}
          onKeyDown={handleKeypress}
        />
        {err && (
          <img
            src={errorSolidError500}
            alt="error-icon"
            width={20}
            height={20}
            className="absolute right-[16px]  bg-white"
          />
        )}
      </div>
      {err && label === error.url.field && (
        <p className="pt-[6px] text-text-sm font-regular text-error-500">{error.url.message}</p>
      )}
      {err && label === error.name.field && (
        <p className="pt-[6px] text-text-sm font-regular text-error-500">{error.name.message}</p>
      )}
    </>
  );
};

export default Input;
