import React from 'react';

export interface ISuggestion {
  publisher: {
    name?: string;
    host: string;
  };
  icon: {
    url: string;
  };
  impressionTracking?: {
    url: string;
    authIsRequired?: boolean;
  }[];
  url: string;
}

export const SponsoredSuggestion = ({ item }: { item: ISuggestion }) => (
  <a
    key={item.publisher?.name || item.publisher.host}
    href={item.url}
    target="_blank"
    rel="noreferrer"
  >
    <div className="flex cursor-pointer items-center gap-[6px] rounded-[12px] p-8 hover:bg-gray-50">
      <div className="relative h-[24px] w-[24px] overflow-hidden rounded-full">
        <img
          src={item.icon.url}
          alt={`${item.publisher?.name || item.publisher.host} icon`}
          className="h-[24px] w-[24px] object-contain"
        />

        <div
          style={{ backgroundImage: `url("${item.icon.url}")` }}
          className={`
              ${
                /**
                 * cross-browser background blur solution
                 * as firefox has a different implementation for blur
                 */ ''
              }
              absolute h-[24px] w-[24px]
              ${/* use z-0, or Dots element is affected by blur */ ''}
              z-0
              scale-[8] bg-[length:2px_2px] bg-repeat
              ${/* apply filters to get desired lightness of background */ ''}
              opacity-20 blur-[1px] saturate-[2]
            `}
        />
      </div>
      <p className="text-text-md font-medium">{item.publisher?.name || item.publisher.host}</p>
    </div>
  </a>
);
