import { atom } from 'recoil';

const settingsAtom = atom({
  key: 'settingsAtom',
  default: {
    settings: {
      tier: 2,
    },
  },
});
export default settingsAtom;
