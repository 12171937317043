/** add missing document types */
declare global {
  interface Document {
    mozFullScreenElement?: Element;
    msFullscreenElement?: Element;
    webkitFullscreenElement?: Element;
  }
}

export const isElementVisible = (node: HTMLElement): boolean => {
  if (window.getComputedStyle(node).visibility === 'hidden') {
    return false;
  }

  return true;
};

export const isDocumentVisible = (): boolean => {
  /**
   * do not check for document.hasFocus(), as Tab has no initial focus
   * (focus is set on browser URL bar)
   */
  if (document.visibilityState === 'hidden') {
    return false;
  }

  if (document.hidden === true) {
    return false;
  }

  return true;
};
