import React, { useContext } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import editModalState from '../../../../utils/atoms/editModalAtom';
import actionModalState from '../../../../utils/atoms/actionModalAtom';
import selectedBookmarkState from '../../../../utils/atoms/selectedBookmarkAtom';
import BookmarksContext from '../../../../context/BookmarksContext';
import { Icon } from '../../../Icon';

export const ActionModal = ({ id }: { id: number }) => {
  const [, setOpenEdit] = useRecoilState(editModalState);
  const [open, setOpen] = useRecoilState(actionModalState);
  const selectedBookmark = useRecoilValue(selectedBookmarkState);
  const { deleteBookmark } = useContext(BookmarksContext);
  const handleOpen = () => {
    setOpenEdit(true);
    setOpen(false);
  };

  const handleDelete = () => {
    deleteBookmark(selectedBookmark.id);
  };

  return (
    <div>
      <div
        className={`absolute  right-[-16px]  top-[20px] z-40 h-[96px] w-[124px] flex-col justify-center rounded-[8px] border-[1px] border-primary-100 bg-white ${
          open && selectedBookmark.id === id ? 'flex' : 'hidden'
        } `}
      >
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => handleOpen()}
          className="mx-auto flex w-[90%] cursor-pointer items-center gap-[8px] rounded-[12px] px-14 py-8 hover:bg-gray-50"
        >
          <Icon path="ui/PenLine" className="h-[24px] w-[24px] text-base-gray" />
          <p className="text-text-md font-medium">Edit</p>
        </div>
        {/* eslint-disable-next-line max-len */}
        {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
        <div
          onClick={() => handleDelete()}
          className="mx-auto flex w-[90%] cursor-pointer items-center gap-[8px] rounded-[12px] px-14 py-8 hover:bg-gray-50"
        >
          <Icon path="ui/TrashLine" className="h-[24px] w-[24px] text-error-500" />
          <p className="text-text-md font-medium text-error-500">Delete</p>
        </div>
      </div>
    </div>
  );
};
