import React from 'react';
import { IconPath } from '../../common/types/icons';

export function Icon({ path, className }: { path: IconPath; className?: string }): JSX.Element {
  if (!path) {
    return <div />;
  }
  const [type, name] = path.split('/');

  return (
    <svg className={className}>
      <use href={`/${type}Sprite.svg#${name}`} />
    </svg>
  );
}
