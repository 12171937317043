type TGlobals = {
  adLimits: {
    banner: number;
    tile: number;
    click: number;
    push: number;
  };
  tiers: {
    [key: number]: {
      tiles: number;
      banners: number;
    };
  };
  defaultSettings: {
    tier: number;
  };
};

const globals: TGlobals = {
  adLimits: {
    banner: 50,
    tile: 40,
    click: 10,
    push: 2,
  },
  tiers: {
    0: {
      tiles: 0,
      banners: 0,
    },
    1: {
      tiles: 0,
      banners: 1,
    },
    2: {
      tiles: 4,
      banners: 2,
    },
    3: {
      tiles: 8,
      banners: 2,
    },
  },
  defaultSettings: {
    tier: 3,
  },
};

export default globals;
